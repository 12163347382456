import { Modal } from "antd";
import React from "react";
import styles from "../GalleryCards.module.scss";
import content from "../../../../settings/content";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import SelectCustom from "../../../../components/ui/SelectCustom/SelectCustom";
import { ISelect } from "../../../../components/ControlPanel/AnalyticsClients/AnalyticsClients";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import { INewProject } from "../GalleryCards";
import TextArea from "antd/es/input/TextArea";

type Props = {
  modalCopy: boolean;
  dealTypes: ISelect[];
  newProject: INewProject;
  roomTypes: ISelect[];
  copyProjectId: string;
  handleModalCopy: (copy: boolean) => void;
  hanldeCopyProjectId: (id: string) => void;
  handleNewProjectChange: (e: React.ChangeEvent<HTMLInputElement> | any) => void;
  handleCreateProject: (projectName: string, room_type_id: string, deal_id: string) => void;
  handleNewProject: (project: INewProject) => void;
};

const GalleryCardsModalSelectDeal: React.FC<Props> = ({
  handleModalCopy,
  modalCopy,
  dealTypes,
  hanldeCopyProjectId,
  handleNewProjectChange,
  newProject,
  roomTypes,
  copyProjectId,
  handleCreateProject,
  handleNewProject,
}) => {
  return (
    <Modal
      className={"custom_shop_modal"}
      onCancel={() => handleModalCopy(false)}
      centered
      width={325}
      open={modalCopy}
      closeIcon={null}
      footer={null}
      zIndex={9999}
      maskClosable={false}
    >
      <div className={styles.gallery_cards_container__modal_header}>
        <span>{content.gallery.detail_card.choose_modal_title}</span>
        <ButtonCustom
          isTransparent
          maxWidth="20px"
          className={styles.gallery_cards_container__modal_close}
          onClick={() => handleModalCopy(false)}
        >
          <CloseIcon />
        </ButtonCustom>
      </div>
      <div className={styles.gallery_cards_container__modal_copy_body}>
        <div className={styles.gallery_cards_container__project_input}>
          <SelectCustom
            options={[...(dealTypes as [])]}
            onChange={(value) => hanldeCopyProjectId(value)}
            onDeselect={() => hanldeCopyProjectId("")}
            placeholder={"Название сделки"}
            width="100%"
            showSearch
          />
        </div>
        <div className={styles.gallery_cards_container__project_input}>
          <InputCustom
            onChange={handleNewProjectChange}
            value={newProject.name}
            name="name"
            placeholder={content.deals.detail_deal_card.placeholder.newProjectName}
            allowClear
          />
        </div>
        <div className={styles.gallery_cards_container__project_input}>
          <SelectCustom
            options={[...(roomTypes as [])]}
            onChange={(value) => handleNewProject({ ...newProject, room_type: value as string[] | any })}
            placeholder={"Тип помещения"}
            width="100%"
            // mode="multiple"
            showSearch
          />
        </div>
        <div className={styles.gallery_cards_container__project_input}>
          <TextArea disabled placeholder={"Комментарий"} allowClear />
        </div>
        <div className={styles.gallery_cards_container__project_input_btn}>
          <ButtonCustom
            className={styles.gallery_cards_container__project_input_btn_copy}
            maxWidth="190px"
            onClick={() => handleCreateProject(newProject.name, newProject.room_type, copyProjectId)}
            disabled={newProject.name.length < 2 || !newProject.room_type.length || !copyProjectId}
          >
            <span>{content.gallery.detail_card.copy_btn}</span>
          </ButtonCustom>
        </div>
      </div>
    </Modal>
  );
};
export default GalleryCardsModalSelectDeal;
