import { DependencyList, useEffect, useRef } from "react";
import { AbortControllerHelper } from "../utils";

export const useEffectAsync = (callback: (abortSignal: AbortSignal) => Promise<void>, deps?: DependencyList) => {
  const ref = useRef(new AbortController());

  useEffect(() => {
    const abortSignal = ref.current.signal;

    const executeCallback = async () => {
      await callback(abortSignal);
    };

    void executeCallback();

    return () => {
      ref.current = AbortControllerHelper.renew(ref.current);
    };
  }, deps);
};
