import React from "react";
import styles from "./Blanks.module.scss";

const Blanks: React.FC = () => {
  return (
    <div className={styles.blanks}>
      <div>Страница в разработке...</div>
    </div>
  );
};

export default Blanks;
