import React, { PropsWithChildren } from "react";
import { useUserNotificationsOrchestrator, Context } from "../shared";

export const UserNotificationsOrchestrator: React.FC<PropsWithChildren> = ({ children }) => {
  const { contextHolder, contextValue } = useUserNotificationsOrchestrator();

  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      {children}
    </Context.Provider>
  );
};
