import { useEffect, useState } from "react";
import { Collapse, CollapseProps, DatePicker, message, Select } from "antd";
import styles from "./FiltersSettings.module.scss";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import { editDealFilter, getDealFilters, IDealFilter } from "../../../../services/DealFilters";
import { useShops } from "../../../../context/useShops";
import { useUser } from "../../../../context/useUser";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import { useMutation, useQuery } from "react-query";
import locale from "antd/es/locale/ru_RU";
import dayjs from "dayjs";
import { onlyEmployee, Role } from "../../../../settings/content";
import NotFoundContent from "../../../../components/ui/SelectCustom/NotFoundContent";

type Props = {
  selectedFilter: IDealFilter;
};

/**
 * TODO: логику работы с фильтрами требуется основательно переработать, в частности,
 *       логику перекрёстного управления выбранными данными
 */
const FilterDetailed = ({ selectedFilter }: Props) => {
  const { shops, allDepartments } = useShops();
  const { user } = useUser();
  const { users } = useUser();
  const [data, setData] = useState<IDealFilter>(selectedFilter);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const { refetch: refetchDealFilters } = useQuery({
    queryFn: () => getDealFilters(),
    queryKey: ["dealFilters"],
  });

  const { mutateAsync: onEditDealFilter } = useMutation({
    mutationFn: editDealFilter,
    onSuccess: (response) => {
      if (response.status === 200) {
        refetchDealFilters();
      }
    },
  });

  const onChangeCollapse = (key: string | string[]) => {
    if (Array.isArray(key)) {
      setActiveKeys(key as string[]);
    } else {
      setActiveKeys([key]);
    }
  };

  const onChange = ({ key, value }: { key: keyof IDealFilter; value: string | string[] | null }) => {
    if (key !== "name") {
      setData((prev) => ({ ...prev, value: { ...prev.value, [key]: value || null } }));
    }
    if (key === "name") {
      setData((prev) => ({ ...prev, name: value as string }));
    }
  };

  useEffect(() => {
    setActiveKeys([]);
    setData(selectedFilter);

    return () => {
      setActiveKeys([]);
    };
  }, [selectedFilter]);

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Магазин",
      children: (
        <div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Выберите магазины"
            onChange={(value) => onChange({ key: "shop_ids" as keyof IDealFilter, value })}
            options={shops.map((shop) => ({ value: shop.id, label: shop.name }))}
            value={data?.value.shop_ids ?? undefined}
            showSearch
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            onDeselect={(value) => {
              setData((prev) => ({
                ...prev,
                value: {
                  ...prev.value,
                  department_ids:
                    prev.value.department_ids?.filter((department) => {
                      const shopId = allDepartments.find((dep) => dep.id === department)?.shop_id;
                      return shopId !== value;
                    }) ?? [],
                  user_ids:
                    prev.value.user_ids?.filter((userId) => {
                      const deletedDepartmentsIds =
                        prev.value.department_ids?.filter((department) => {
                          const shopId = allDepartments.find((dep) => dep.id === department)?.shop_id;
                          return shopId === value;
                        }) || [];
                      return users.filter((user) => deletedDepartmentsIds.includes(user.department_id)).every((x) => x.id !== userId);
                    }) || [],
                },
              }));
            }}
            notFoundContent={<NotFoundContent />}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Отдел",
      children: (
        <div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Выберите отделы"
            onChange={(value) => onChange({ key: "department_ids" as keyof IDealFilter, value })}
            options={(() => {
              const resultDepartments = allDepartments
                .map((department) => ({ value: department.id, label: department.name, shop_id: department.shop_id }))
                .filter((department) =>
                  [Role.ORGANIZATION_MANAGER].includes(user.role as Role)
                    ? data.value.shop_ids?.includes(department.shop_id)
                    : user.shop_id === department.shop_id,
                );
              const shopIds = resultDepartments.map((department) => department.shop_id);
              const uniqueShopIds = new Set(shopIds);
              const resultDepartmentsWithShopIds = Array.from(uniqueShopIds).map((shopId) => {
                const shop = shops.find((shop) => shop.id === shopId);
                return {
                  label: shop?.name ?? shop?.id,
                  title: shop?.name ?? shop?.id,
                  options: resultDepartments.filter((department) => department.shop_id === shopId),
                };
              });
              return resultDepartmentsWithShopIds;
            })()}
            value={data?.value.department_ids ?? undefined}
            showSearch
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            notFoundContent={<NotFoundContent />}
            onDeselect={(deletedDepartmentId) => {
              setData((prev) => ({
                ...prev,
                value: {
                  ...prev.value,
                  user_ids:
                    prev.value.user_ids?.filter((userId) =>
                      users.filter((user) => user.department_id === deletedDepartmentId).every((user) => user.id !== userId),
                    ) || [],
                },
              }));
            }}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Продавец",
      children: (
        <div>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Выберите продавца"
            onChange={(value) => onChange({ key: "user_ids" as keyof IDealFilter, value })}
            options={users
              .filter(
                (userItem) =>
                  onlyEmployee.includes(userItem.role as Role) &&
                  (data.value.department_ids?.includes(userItem.department_id) ||
                    (!data.value.department_ids &&
                      (user.department_id ? userItem.department_id === user.department_id : userItem.shop_id === user.shop_id))),
              )
              .map((user) => ({ value: user.id, label: user.name }))}
            value={data?.value.user_ids ?? undefined}
            showSearch
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            notFoundContent={<NotFoundContent />}
          />
        </div>
      ),
    },
    {
      key: "4",
      label: "Дата",
      children: (
        <DatePicker.RangePicker
          style={{ width: "100%" }}
          value={[
            data?.value.period_start && data.value.period_start !== "" ? dayjs(data.value.period_start) : null,
            data?.value.period_end && data.value.period_end !== "" ? dayjs(data.value.period_end) : null,
          ]}
          onChange={(value) => {
            if (value === null) {
              onChange({ key: "period_start" as keyof IDealFilter, value: null });
              onChange({ key: "period_end" as keyof IDealFilter, value: null });
            }
            if (value && value.length > 0) {
              if (value[0]) {
                const dateFrom = dayjs(value[0], "DD.MM.YYYY");
                onChange({ key: "period_start" as keyof IDealFilter, value: dateFrom.toISOString() });
              }
              if (value[1]) {
                const dateTo = dayjs(value[1], "DD.MM.YYYY");

                onChange({ key: "period_end" as keyof IDealFilter, value: dateTo.toISOString() });
              }
            }
          }}
          locale={locale.DatePicker}
          format={"DD.MM.YYYY"}
        />
      ),
    },
  ].filter((item) => {
    if (item.key === "1" && [Role.ORGANIZATION_MANAGER].includes(user.role as Role)) {
      return item;
    }
    if (item.key === "2" && [Role.ORGANIZATION_MANAGER, Role.SHOP_MANAGER].includes(user.role as Role)) {
      return item;
    }
    if (item.key === "3") {
      return item;
    }
    if (item.key === "4") {
      return item;
    }
  });

  return (
    <div className={styles.filterDetailed}>
      <InputCustom
        isError={!data.name}
        value={data.name}
        onChange={(e) => onChange({ key: "name" as keyof IDealFilter, value: e.target.value })}
        placeholder={"Название фильтра"}
        style={{ width: "300px" }}
      />
      <Collapse items={items} defaultActiveKey={[]} onChange={onChangeCollapse} activeKey={activeKeys} />
      <ButtonCustom
        onClick={(e) => {
          if (data.name.length > 0) {
            onEditDealFilter(data).then((response) => {
              if (response.status === 200) {
                message.success("Фильтр успешно отредактирован");
              } else {
                message.error("Произошла ошибка при редактировании фильтра: " + JSON.stringify(response.data));
              }
            });
          }
        }}
        maxWidth="fit-content"
        className={styles.filtersList__item_save_btn}
        disabled={!data.name || dayjs(data.value.period_start).isAfter(dayjs(data.value.period_end))}
      >
        <span>Сохранить</span>
      </ButtonCustom>
    </div>
  );
};

export default FilterDetailed;
