import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import ButtonCustom from "../../../../components/ui/ButtonCustom/ButtonCustom";
import SelectCustom from "../../../../components/ui//SelectCustom/SelectCustom";
import TextArea from "antd/es/input/TextArea";
import { Form, Modal, Tabs, message } from "antd";
import {
  IDeal,
  IDealStatusResponse,
  SendingMethodsEnum,
  SendingMethodsList,
  editDeal,
  editDealStatus,
  getCommentsHistoryByDeal,
  getDeal,
  getHistoryDealStatuses,
  getNotificationSettingsById,
  postNotificationSettingsById,
  sendComment,
} from "../../../../services/Deals";
import Loader from "../../../../components/ui/Loader/Loader";
import DetailCardCreatedBy from "./DetailCardCreatedBy/DetailCardCreatedBy";
import DetailCardHistoryStatuses from "./DetailCardHistoryStatuses/DetailCardHistoryStatuses";
import moment from "moment";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cross.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/icons/calendar.svg";
import { ReactComponent as SendIcon } from "../../../../assets/icons/send.svg";
import InputCustom from "../../../../components/ui/InputCustom/InputCustom";
import { IProject, createProject, getProjectById, getProjectsByDeal } from "../../../../services/Projects";
import DetailCardProjects from "./DetailCardProjects/DetailCardProjects";
import DetailCardAdditionalGoods from "./DetailCardAdditionalGoods/DetailCardAdditionalGoods";
import { NoticeType } from "antd/es/message/interface";
import styles from "./DealCardDetail.module.scss";
import "./DealCardDetail_override.scss";
import content, { onlyEmployee, onlyLPR, Role } from "../../../../settings/content";
import DealCardReminder from "./DealCardReminder/DealCardReminder";
import Gallery from "../../../Gallery/Gallery";
import { useUser } from "../../../../context/useUser";
import { INotification, getRemindersByDealId } from "../../../../services/Reminders";
import { useNotificationsContext } from "../../../../context/useNotifications";
import dayjs from "dayjs";
import ModalCustom from "../../../../components/ModalCustom/ModalCustom";
import SendPanoModal from "./SendPanoModal/SendPanoModal";
import { PhoneInput } from "react-international-phone";
import { PHONE_COUNTRIES } from "../../../../settings/countries";
import ValidateComponentByRole from "../../../../components/ValidateComponentByRole/ValidateComponentByRole";
import { useSources } from "../../../../context/useSources";

export interface IDealCardDetailProps {
  id: string;
  onCancel: () => void;
  updateDealsList: () => void;
  alertUpdate: () => void;
  statusesData: IDealStatusResponse[];
}

interface INewProject {
  name: string;
  room_type: string;
}

const initialProject: INewProject = {
  name: "",
  room_type: "",
};

const initialState: IDeal = {
  client_id: "",
  comment: "",
  created_at: "",
  created_by: "",
  department_id: "",
  estate_name: "",
  id: "",
  renovation_stage: "",
  room_type: "",
  status: "",
  surface_area: 0,
  title: "",
  client_fullname: "",
  shop_id: "",
};
interface ISourceSelect {
  value: string;
  label: string;
}

const DealCardDetail: React.FC<IDealCardDetailProps> = ({ id, onCancel, statusesData, updateDealsList, alertUpdate }) => {
  const { user } = useUser();
  const { room_types } = useSources();
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState<IDeal>(initialState);
  const [isCreatingProject, setIsCreatingProject] = useState<{ isCreating: boolean; isCopying: string }>({
    isCreating: false,
    isCopying: "",
  });
  const [isCreatingProjectLocal, setIsCreatingProjectLocal] = useState<boolean>(false);
  const [newProject, setNewProject] = useState<INewProject>(initialProject);
  const [projectsByDeal, setProjectByDeal] = useState<IProject[]>([]);
  const [isModalLeadOpen, setIsModalLeadOpen] = useState<boolean>(false);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [commentTyping, setCommentTyping] = useState<string>("");
  const [isSendPano, setIsSendPano] = useState("");
  const { setMailingNotification } = useNotificationsContext();
  const [currentSendingMethod, setCurrentSendingMethod] = useState<SendingMethodsEnum>();
  const [copyingModalTitle, setCopyingModalTitle] = useState<string>("");

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const { data: dealData, refetch: refetchDealData } = useQuery({
    queryFn: () => getDeal({ id }),
    queryKey: [`deal_${id}`],
    enabled: !!id,
  });

  const { data: statusesHistory, refetch: refetchStatusesHistory } = useQuery({
    queryFn: () => getHistoryDealStatuses(id),
    queryKey: ["statusesHistory"],
  });

  const { data: commentsHistory, refetch: refetchCommentsHistory } = useQuery({
    queryFn: () => getCommentsHistoryByDeal(id),
    queryKey: ["commentsHistory"],
  });

  const { data: notificationsSettingsData, refetch: refetchNotificationsSettings } = useQuery({
    queryFn: () => getNotificationSettingsById(id),
    queryKey: ["notificationsSettings"],
    onSuccess: (data) => {
      setCurrentSendingMethod(data.data.sending_method);
    },
  });

  const { data: remindersData, refetch: refetchReminders } = useQuery({
    queryFn: () => getRemindersByDealId(id),
    queryKey: [`reminders`],
  });

  const updateHistory = () => {
    refetchCommentsHistory();
    refetchStatusesHistory();
  };

  const { mutateAsync: onEditDeal } = useMutation({
    mutationFn: editDeal,
    onSuccess: () => {
      updateDealsList();
    },
  });

  const { mutateAsync: onEditDealStatus } = useMutation({
    mutationFn: editDealStatus,
    onSuccess: () => {
      updateDealsList();
    },
  });

  const { mutateAsync: onSendMessage } = useMutation({
    mutationFn: sendComment,
    onSuccess: () => {
      refetchCommentsHistory();
      setCommentTyping("");
    },
  });

  const { mutateAsync: onChangeSendingMethod } = useMutation({
    mutationFn: postNotificationSettingsById,
  });

  const { data: projectsData, refetch } = useQuery({
    queryFn: () => getProjectsByDeal({ id }),
    queryKey: ["projectsByDeal" + id],
  });

  // Получим данные для инпутов при открытии модального окна
  useEffect(() => {
    if (dealData?.data) {
      setData(dealData.data);
    }
  }, [dealData, id]);

  useEffect(() => {
    if (projectsData?.data) {
      setProjectByDeal(projectsData.data);
    }
  }, [projectsData]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCommentTyping(e.target.value);
  };

  const handleNewProjectChange = (e: React.ChangeEvent<HTMLInputElement> | any): void => {
    setNewProject({ ...newProject, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    onEditDeal({
      id: id,
      title: data.title,
      client_id: data.client_id,
      room_type: data.room_type,
      surface_area: +data.surface_area,
      renovation_stage: data.renovation_stage,
      estate_name: data.estate_name,
      comment: data.comment,
    }).then((response) => {
      if (response.status === 200) {
        alertUpdate();
        <Loader />;
        onCancel();
      } else {
        alert("error", content.alert_messages.deals.edit.error_deal);
      }
    });
  };

  const handleStatusChange = (value: string) => {
    onEditDealStatus({ dealId: id, status: value }).then((response) => {
      refetch();
      refetchDealData();
      refetchStatusesHistory();
      const statusValue = response.data?.project_notification_status;
      if (
        statusValue &&
        statusValue !== "NO_EMAIL_BINDING" &&
        statusValue !== "NO_PHONE_BINDING" &&
        statusValue !== "NO_EMAIL_AND_PHONE_BINDING"
      ) {
        const comment = (() => {
          if (response.data?.project_notification_status && response.data.project_notification_status === "SUCCESS") {
            return `Уведомление клиенту ${data.client_fullname} успешно отправлено`;
          } else if (response.data?.project_notification_status_text) {
            return "Уведомление клиенту не отправлено. Ошибка: " + response.data?.project_notification_status_text;
          }
        })();
        const obj: INotification = {
          id: "",
          created_at: "",
          deal_id: response.dealId,
          lead_id: null,
          created_by: user.id,
          comment: comment ?? "",
          notify_at: dayjs().format(),
          postponed_until: "",
          processed: false,
          was_read: false,
          isSuccess: response.data.project_notification_status === "SUCCESS",
        };
        setMailingNotification([obj]);
      }
    });
  };

  const handleSendingMethodChange = (value: string) => {
    onChangeSendingMethod({ deal_id: id, sending_method: value as SendingMethodsEnum }).then((response) => {
      if (response.status === 200) {
        setCurrentSendingMethod(value as SendingMethodsEnum);
      }
    });
  };

  const getDataInput = (index: string) => data[index as keyof IDeal];

  const handleCloseModal = () => onCancel();

  const handleCreateProject = (projectName: string, room_type: string) => {
    createProject({
      deal_id: id,
      project_name: projectName,
      room_type: room_type,
      template_project_id: isCreatingProject.isCopying,
    }).then((response) => {
      setNewProject(initialProject);
      const link = document.createElement("a");
      link.href =
        isCreatingProject.isCopying === ""
          ? `ceramic3d:?project_id=${response.data.id}&projectName=${projectName}`
          : `ceramic3d:?project_id=${response.data.id}&template_id=${isCreatingProject.isCopying}&projectName=${projectName}`;
      link.target = "_blank";
      link.click();
      setIsCreatingProject({ isCopying: "", isCreating: false });
      refetch();
    });
  };

  const handleCreateProjectFromLocal = (projectName: string, room_type: string) => {
    createProject({ deal_id: id, project_name: projectName, room_type: room_type }).then((response) => {
      if (response.status === 200) {
        setIsCreatingProject({ isCopying: "", isCreating: false });
        setIsCreatingProjectLocal(false);
        setNewProject(initialProject);
        refetch();
      }
      setNewProject(initialProject);
      const link = document.createElement("a");
      link.href = `ceramic3d:?project_id=${response.data.id}&projectName=${projectName}&open_local_project`;
      link.target = "_blank";
      link.click();
    });
  };

  const tabs = [
    {
      label: content.deals.detail_deal_card.tabs.history.label,
      key: "1",
      children: (
        <>
          <DetailCardHistoryStatuses
            statusesHistory={
              Array.isArray(statusesHistory)
                ? statusesHistory?.sort(function (a, b) {
                    return moment(a.created_at).diff(moment(b.created_at));
                  })
                : []
            }
            statusesData={statusesData}
            commentsHistory={commentsHistory ?? []}
            updateHistory={updateHistory}
            remindersData={remindersData}
          />
          <ValidateComponentByRole requiredRoles={onlyEmployee}>
            <div className={styles.dealCardDetail__comment}>
              <span className={styles.dealCardDetail__comment_label}>{content.deals.detail_deal_card.comment.label}</span>
              <Form className={styles.dealCardDetail__comment_textBlock}>
                <TextArea
                  rows={2.5}
                  placeholder={content.deals.detail_deal_card.comment.placeholder}
                  name={"comment"}
                  onChange={onCommentChange}
                  value={commentTyping}
                  onPressEnter={() => onSendMessage({ deal_id: id, text: commentTyping })}
                  maxLength={250}
                />
                <ButtonCustom
                  className={styles.dealCardDetail__send_button}
                  isTransparent
                  onClick={() => onSendMessage({ deal_id: id, text: commentTyping })}
                  htmlType="submit"
                  disabled={commentTyping.length < 1}
                >
                  <SendIcon />
                </ButtonCustom>
              </Form>
            </div>
          </ValidateComponentByRole>
        </>
      ),
    },
    {
      label: content.deals.detail_deal_card.tabs.projects.label,
      key: "2",
      children: (
        <DetailCardProjects
          projectsByDeal={projectsByDeal}
          userInfo={user}
          dealStatus={data.status}
          refetchProjects={refetch}
          isDealClosed={["closed", "sold", "service", "reject"].includes(data.status)}
          onCopyProject={setIsCreatingProject}
        />
      ),
    },
  ];

  // const openCreateLeadModal = () => {
  //   setIsModalLeadOpen(true);
  // };

  const closeCreateLeadModal = () => {
    setIsModalLeadOpen(false);
  };

  const handleCloseReminer = () => {
    setReminderOpen(false);
  };

  const isCurrentUserLpr = () => {
    return onlyLPR.includes(user.role as Role);
  };

  useEffect(() => {
    if (isCreatingProject.isCopying !== "" && isCreatingProject.isCopying) {
      const request = getProjectById(isCreatingProject.isCopying).then((response) => setCopyingModalTitle(response.data.name));
    }
  }, [isCreatingProject]);

  return (
    <Modal
      key={id}
      centered
      width={650}
      open={id ? true : false}
      closeIcon={false}
      onCancel={onCancel}
      className="custom-styles"
      footer={null}
      maskClosable={false}
    >
      <div className={styles.dealCardDetail}>
        {contextHolder}
        <div className={styles.dealCardDetail__header}>
          <p>{dealData?.data.title}</p>
          <ButtonCustom className={styles.dealCardDetail__header_close} onClick={handleCloseModal} isTransparent>
            <CloseIcon />
          </ButtonCustom>
        </div>

        <div className={styles.dealCardDetail__block}>
          <div className={styles.dealCardDetail__createdBy}>
            <DetailCardCreatedBy created_by={data.created_by} created_at={data.created_at} shop_id={data.shop_id} />
          </div>

          <div className={styles.dealCardDetail__status}>
            <span className={styles.dealCardDetail__status_title}>{content.deals.detail_deal_card.status_title}</span>
            <SelectCustom
              defaultValue={data.status}
              options={statusesData?.map((status) => ({
                value: status.value,
                label: status.name,
              }))}
              onChange={handleStatusChange}
              value={statusesData.find((item) => item.value === data.status)?.name ?? data.status}
              width="25%"
              disabled={isCurrentUserLpr()}
            />
          </div>

          <div className={styles.dealCardDetail__inputs}>
            {content.deals.detail_deal_card.dealCardDetailedColumns.map((column) => {
              return (
                <div className={styles.dealCardDetail__inputs_row} key={column?.toString()}>
                  {column.map((input, index) => {
                    return (
                      <div key={index} className={styles.dealCardDetail__input_block}>
                        {input.name === "client_phone" ? (
                          <PhoneInput
                            defaultCountry="ru"
                            value={getDataInput(input.name)?.toString() || ""}
                            placeholder={content.login.form.phone.placeholder}
                            countries={PHONE_COUNTRIES}
                            disabled={true}
                            className={styles.dealCardDetail__input}
                            name={input.name}
                            forceDialCode
                          />
                        ) : (
                          <InputCustom
                            type={input.name === "surface_area" ? "number" : "text"}
                            className={styles.dealCardDetail__input}
                            name={input.name}
                            onChange={handleChange}
                            value={getDataInput(input.name) || undefined}
                            placeholder={input.placeholder}
                            maxLength={input.name === "surface_area" ? 10 : 100}
                            // key={index + input.id}
                            allowClear
                            disabled={input.name === "client_email" || input.name === "client_fullname" || isCurrentUserLpr()}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className={styles.dealCardDetail__reminder}>
            {notificationsSettingsData?.data && (
              <div className={styles.dealCardDetail__sendingmethod}>
                <span className={styles.dealCardDetail__sendingmethod_title}>Метод отправки</span>
                <SelectCustom
                  options={SendingMethodsList}
                  onChange={handleSendingMethodChange}
                  value={currentSendingMethod}
                  width="150px"
                  disabled={!onlyEmployee.includes(user.role as Role)}
                />
              </div>
            )}
            <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <ButtonCustom
                isTransparent
                className={styles.dealCardDetail__reminder_button}
                maxWidth="40px"
                onClick={() => setReminderOpen(true)}
              >
                <CalendarIcon />
              </ButtonCustom>
              {remindersData && (
                <DealCardReminder
                  deal_id={id}
                  isModalOpen={reminderOpen}
                  handleCloseReminer={handleCloseReminer}
                  remindersData={remindersData}
                  refetchReminders={refetchReminders}
                />
              )}
            </ValidateComponentByRole>
          </div>

          <div className={styles.dealCardDetail__button_container}>
            {/* <ButtonCustom
              className={styles.dealCardDetail__button}
              maxWidth={"none"}
              children={<span>{content.deals.detail_deal_card.buttons.title.add_goods}</span>}
              onClick={openCreateLeadModal}
            /> */}
            <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <ButtonCustom
                className={styles.dealCardDetail__button}
                maxWidth={"none"}
                children={<span>{content.deals.detail_deal_card.buttons.title.create_project}</span>}
                onClick={() => setIsCreatingProject({ isCreating: true, isCopying: "" })}
              />
            </ValidateComponentByRole>
            <ButtonCustom
              className={styles.dealCardDetail__button}
              maxWidth={"none"}
              onClick={() => setShowGallery(true)}
              children={<span>{content.deals.detail_deal_card.buttons.title.show_gallery}</span>}
            />
            <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <ButtonCustom
                className={styles.dealCardDetail__button}
                maxWidth={"none"}
                children={<span>{content.deals.detail_deal_card.buttons.title.open_project}</span>}
                onClick={() => setIsCreatingProjectLocal(true)}
              />
            </ValidateComponentByRole>
            <ButtonCustom
              className={styles.dealCardDetail__button}
              maxWidth={"none"}
              disabled={true}
              children={<span>{content.deals.detail_deal_card.buttons.title.create_apartment}</span>}
            />
            <ValidateComponentByRole requiredRoles={onlyEmployee}>
              <ButtonCustom className={styles.dealCardDetail__button} onClick={() => setIsSendPano(data.id)}>
                <span>{content.deals.detail_deal_card.tabs.projects.button_block.sendPanorama}</span>
              </ButtonCustom>
            </ValidateComponentByRole>
          </div>

          <div className={styles.dealCardDetail__tabs}>
            <Tabs defaultActiveKey="1" items={tabs} />
          </div>

          <ValidateComponentByRole requiredRoles={onlyEmployee}>
            <div className={styles.dealCardDetail__submit}>
              <ButtonCustom className={styles.custom_button} onClick={handleSubmit} disabled={false}>
                <span>{content.deals.detail_deal_card.save_button}</span>
              </ButtonCustom>
            </div>
          </ValidateComponentByRole>
        </div>
      </div>
      <Modal
        key={id + "projectname" + data.created_at}
        centered
        width={360}
        open={isCreatingProject.isCreating}
        className={"custom_shop_modal"}
        closeIcon={false}
        onCancel={() => {
          setIsCreatingProject({ isCreating: false, isCopying: "" });
          setNewProject(initialProject);
        }}
        footer={null}
        maskClosable={false}
      >
        <div>
          <div className={styles.dealCardDetail__modal_header}>
            <span className={styles.dealCardDetail__modal_title}>{copyingModalTitle}</span>
            <ButtonCustom
              isTransparent
              maxWidth="20px"
              className={styles.dealCardDetail__modal_close}
              onClick={() => {
                setIsCreatingProject({ isCreating: false, isCopying: "" });
                setNewProject(initialProject);
              }}
            >
              <CloseIcon />
            </ButtonCustom>
          </div>
          <div className={styles.dealCardDetail__modal_wrapper}>
            <div className={styles.dealCardDetail__projectName_title}>
              <p>{content.deals.header.block_title}</p>
            </div>
            <div className={styles.dealCardDetail__projectName_input}>
              <InputCustom
                onChange={handleNewProjectChange}
                value={newProject.name}
                name="name"
                placeholder={content.deals.detail_deal_card.placeholder.newProjectName}
                allowClear
              />
            </div>
            <div className={styles.dealCardDetail__projectName_input}>
              <SelectCustom
                className={styles.dealCardDetail__roomtype_select}
                options={room_types.map((item) => ({ label: item.name, value: item.value }))}
                onChange={(value) => setNewProject((prev) => ({ ...prev, room_type: value }))}
                placeholder={content.deals.detail_deal_card.placeholder.newProjectRoomType}
                width="100%"
                value={newProject.room_type !== "" ? newProject.room_type : undefined}
                showSearch
              />
            </div>
            <div className={styles.dealCardDetail__projectName_confirm}>
              <ButtonCustom
                className={styles.custom_button}
                maxWidth="150px"
                onClick={() => {
                  const roomTypeId = room_types.find((item) => item.value === newProject.room_type)?.value;
                  if (!roomTypeId) {
                    return;
                  }
                  handleCreateProject(newProject.name, roomTypeId);
                }}
                disabled={newProject.name.length < 2 || !newProject.room_type.length}
                children={<span>{content.deals.detail_deal_card.buttons.title.create_project}</span>}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        key={id + "_projectname"}
        centered
        width={340}
        open={isCreatingProjectLocal}
        closeIcon={false}
        onCancel={() => {
          setIsCreatingProjectLocal(false);
          setNewProject(initialProject);
        }}
        className={"custom_shop_modal"}
        footer={false}
        maskClosable={false}
      >
        <div className={styles.dealCardDetail__modal_header}>
          <span>{content.deals.detail_deal_card.buttons.title.project_name}</span>
          <ButtonCustom
            isTransparent
            maxWidth="20px"
            className={styles.dealCardDetail__modal_close}
            onClick={() => setIsCreatingProjectLocal(false)}
          >
            <CloseIcon />
          </ButtonCustom>
        </div>
        <div className={styles.dealCardDetail__projectName_wrapper}>
          <p className={styles.dealCardDetail__projectName_title}>{content.deals.detail_deal_card.buttons.title.project_title}</p>
          <div className={styles.dealCardDetail__projectName_input}>
            <InputCustom
              onChange={handleNewProjectChange}
              value={newProject.name}
              name="name"
              placeholder={content.deals.detail_deal_card.placeholder.newProjectName}
              allowClear
            />
          </div>
          <div className={styles.dealCardDetail__projectName_input}>
            <SelectCustom
              options={room_types.map((item) => ({ label: item.name, value: item.value }))}
              onChange={(value) => setNewProject((prev) => ({ ...prev, room_type: value }))}
              placeholder={content.deals.detail_deal_card.placeholder.newProjectRoomType}
              value={newProject.room_type !== "" ? newProject.room_type : undefined}
              width="100%"
              showSearch
            />
          </div>
          <div className={styles.dealCardDetail__projectName_footer}>
            <ButtonCustom
              maxWidth="130px"
              className={styles.dealCardDetail__projectName_footer_btn}
              onClick={() => handleCreateProjectFromLocal(newProject.name, newProject.room_type)}
              disabled={newProject.name.length < 2 || !newProject.room_type.length}
              children={<span>{content.deals.detail_deal_card.buttons.title.create_project}</span>}
            />
          </div>
        </div>
      </Modal>
      <Modal
        key={id + "projectname_"}
        centered
        width={1200}
        open={showGallery}
        closeIcon={false}
        onCancel={() => {
          setShowGallery(false);
        }}
        footer={null}
        maskClosable={false}
      >
        <div className={styles.dealCardDetail__gallery_header}>
          <ButtonCustom
            onClick={() => setShowGallery(false)}
            maxWidth={"40px"}
            isTransparent
            className={styles.dealCardDetail__gallery_header_close}
          >
            <CloseIcon />
          </ButtonCustom>
        </div>
        <Gallery isOpenedFromDeal={id} />
      </Modal>
      <ModalCustom
        title={"Отправить клиенту панорамы"}
        handleCloseModal={() => setIsSendPano("")}
        destroyOnClose={true}
        centered
        width={900}
        isOpen={isSendPano !== ""}
        onCancel={() => setIsSendPano("")}
        footer={null}
      >
        <SendPanoModal
          projectId={isSendPano}
          deal_id={id}
          project_id_list={projectsByDeal.map((item) => item.id)}
          closeModal={() => setIsSendPano("")}
        />
      </ModalCustom>
      <DetailCardAdditionalGoods open={isModalLeadOpen} onCancel={closeCreateLeadModal} deal_id={id} />
    </Modal>
  );
};

export default DealCardDetail;
