import { useEffect, useState } from "react";
import GalleryCardDetailed from "../../Gallery/GalleryCardDetailed/GalleryCardDetailed";
import { useLocation } from "react-router-dom";
import GalleryCardItems from "../../Gallery/GalleryCardItems/GalleryCardItems";
import { ReactComponent as CeramicIcon } from "../../../assets/icons/Ceramic.svg";
import styles from "./PublicProjectView.module.scss";

type Props = {};

const PublicProjectView = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isOldProjectViewPath = location.search.includes("projectId") ?? "";

  const projectId = (() => {
    if (isOldProjectViewPath) {
      return queryParams.get("projectId") ?? "";
    }
    return location.pathname.split("/")[2];
  })();

  const [isPanoramaError, setIsPanoramaError] = useState(false);
  const handlePanoramaError = (isError: boolean) => setIsPanoramaError(isError);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.publicGallery}>
      <div className={styles.header}>
        <CeramicIcon />
      </div>
      <div className={styles.publicGallery__block}>
        <GalleryCardDetailed selectedProjectId={projectId} isErrorHandler={handlePanoramaError} isPublicGallery isOpenedFromProjectView />
        <GalleryCardItems selectedProjectId={projectId ?? ""} isPublicGallery />
      </div>
    </div>
  );
};

export default PublicProjectView;
