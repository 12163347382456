import axios from "axios";
import { IDealRequestById } from "./Deals";
import { ProjectStatusType } from "../helpers/projectStatus";
import Cookies from "js-cookie";
import { baseUrl5050, headers, yandexCloudUrl } from "../env";
import { buildAuthHeader, buildPresalesUrl } from "../shared";

export enum IProjectSaleStatuses {
  ready_for_sale = "ready_for_sale",
  rejected = "rejected",
  sold = "sold",
}

interface IProjectRequest {
  id?: string;
  deal_id?: string;
  status?: ProjectStatusType;
  room_type_id?: string;
  name: string;
}

interface IGetProjectResponse {
  data: {
    items: IProject[];
    count: number;
  };
  status: number;
}

interface IGetProjectByIdResponse {
  data: IProject;
  status: number;
}

export interface IProjectJson {
  pano_small: string;
  pano_middle: string;
  pano: string;
  preview: string;
  meta: string;
}

interface IGetProjectJsonByIdResponse {
  data: {
    items: IProjectJson[];
  };
  status: number;
}

export interface IProject {
  created_by: string;
  deal_id: string;
  id: string;
  name: string;
  status: ProjectStatusType;
  created_at: string;
  room_type_id: string;
  sale_status: IProjectSaleStatuses;
  number: string;
  room_type: string;
}

export interface IAllProjectsByDeal {
  data: IProject[];
  status: string;
}

export interface IProjectPages {
  offset?: number;
  limit?: number;
  room_type_id?: string;
  collection_id?: string;
  status?: string;
  user_id?: string;
  shop_id?: string;
  city_id?: string;
  search_query?: string;
  manufacturer_id?: string;
}

export interface IPublicProjectPages {
  offset?: number;
  limit?: number;
  room_type_id?: string;
  collection_id?: string;
  organization_id: string;
  search_query?: string;
  manufacturer_id?: string;
}

export interface ICreateProjectRequest {
  deal_id: string;
  project_name: string;
  room_type: string;
  template_project_id?: string | null;
}

export interface ICreateProjectResponse {
  data: { deal_id: string; id: string; name: string };
  status: number;
}

export interface IProjectItem {
  id: string;
  resource_id: string;
  name: string;
  vendor_code: string;
  count: number;
  square: number;
  collection: string;
  manufacturer: string;
}

export interface IGetProjectItems {
  data: IProjectItem[];
  status: number;
}

export interface IGetNotificationText {
  data: { status: string; text: string };
  status: number;
}

interface IProjectStatus {
  id: string;
  status: ProjectStatusType;
}

interface IProjectStatusResponse {
  status: number;
}

export interface IProjectStatusObj {
  value: string;
  name: string;
}

interface IGetProjectStatuses {
  data: IProjectStatusObj[];
  status: number;
}

export interface ISendPanosToClients {
  deal_id: string;
  text: string;
}

export interface ISendPanosToClientResponse {
  data: { status: string; status_text: string | null };
  status: number;
}

export interface IChangeProjectSaleStatus {
  id: string;
  sale_status: IProjectSaleStatuses;
}

export interface IPhotoJson {
  indx: string;
  kind: string;
  name: string;
  file: string;
}

interface IGetProjectPhotoJsonByIdResponse {
  data: {
    items: IPhotoJson[];
  };
  status: number;
}

// Метод для добавления объекта в БД
export async function addProject(projectRequest: IProjectRequest): Promise<IProject> {
  const id = projectRequest.id;
  // удалим из объекта поле id. Так как если в передаваемом объекте есть id
  // получим ошибку.
  delete projectRequest.id;

  return axios
    .post(baseUrl5050 + `project/${id}`, projectRequest, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        id: response.data?.id,
        deal_id: response.data?.deal_id,
        name: response.data.name,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при записи объекта в методе ==> addProject:", err.response);
      return err.response;
    });
}

export async function createProject(createProjectRequest: ICreateProjectRequest): Promise<ICreateProjectResponse> {
  return axios
    .post(
      baseUrl5050 + `project`,
      {
        name: createProjectRequest.project_name,
        room_type: createProjectRequest.room_type,
        deal_id: createProjectRequest.deal_id,
        template_project_id: createProjectRequest.template_project_id !== "" ? createProjectRequest.template_project_id : null,
      },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при создании проекта в методе ==> createProject:", err.response);
      return err.response;
    });
}

// Метод для получения статусов проектов из БД
export async function getProjectStatuses(): Promise<IGetProjectStatuses> {
  return axios
    .get(baseUrl5050 + `project-status`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data.items,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getProjectStatuses:", err.response);
      return err.response;
    });
}

// Метод для получения объекта из БД по ID
export async function getProjectById(id: string, isGalleryProject?: boolean): Promise<IGetProjectByIdResponse> {
  const url = buildPresalesUrl(`${isGalleryProject ? "gallery-project" : "project"}/${id}`);

  return axios
    .get(url, { headers: buildAuthHeader() })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getProjectById:", err.response);
      return err.response;
    });
}

// Метод для получения объекта из БД по ID
export async function getPublicProjectById(id: string): Promise<IGetProjectByIdResponse> {
  return axios
    .get(baseUrl5050 + `public/project/${id}`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getPublicProjectById:", err.response);
      return err.response;
    });
}

// Метод для получения JSON из YandexCloud по ID
export async function getProjectJsonById(id: string): Promise<IGetProjectJsonByIdResponse> {
  try {
    const request = await axios.get(`${yandexCloudUrl}${id}/pano.json`);
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getProjectJsonById:");
    throw new Error(err.request.status);
  }
}

// Метод для получения JSON из YandexCloud по ID
export async function getProjectPhotosJsonById(id: string): Promise<IGetProjectPhotoJsonByIdResponse> {
  try {
    const request = await axios.get(`${yandexCloudUrl}${id}/photos.json`);
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getProjectPhotosJsonById:");
    throw new Error(err.request.status);
  }
}

export async function getProjects(projectPages: IProjectPages, isMyProjects?: boolean): Promise<IGetProjectResponse> {
  const offset = projectPages.offset || projectPages.offset === 0 ? "?offset=" + projectPages.offset : "?";
  const limit = projectPages.limit ? "&limit=" + projectPages.limit : "";
  const room_type_id = projectPages.room_type_id ? "&room_type=" + projectPages.room_type_id : "";
  const collection_id = projectPages.collection_id ? "&collection_id=" + projectPages.collection_id : "";
  const status_id = projectPages.status ? "&status=" + projectPages.status : "";
  const user_id = projectPages.user_id ? "&user_id=" + projectPages.user_id : "";
  const manufacturer_id = projectPages.manufacturer_id ? `&manufacturer_id=${projectPages.manufacturer_id}` : "";
  const shop_id = (() => {
    if (!projectPages.shop_id) {
      return "";
    }
    if (projectPages.shop_id.includes(",")) {
      const shopsArr = projectPages.shop_id.split(",");
      const resultStr = shopsArr.map((item) => `&shop_id=${item}`).join("");
      return resultStr;
    } else {
      return `&shop_id=${projectPages.shop_id}`;
    }
  })();
  const search_query =
    projectPages.search_query && projectPages.search_query.length > 0 ? "&search_query=" + projectPages.search_query : "";

  try {
    const totalURL = `${baseUrl5050}${isMyProjects ? "project" : "gallery-project"}${[
      offset,
      limit,
      room_type_id,
      collection_id,
      manufacturer_id,
      status_id,
      user_id,
      shop_id,
      search_query,
    ].join("")}`;

    const request = await axios.get(totalURL, {
      headers,
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getProjects:");
    throw new Error(err.request.status);
  }
}

export async function getPublicProjects(projectPages: IPublicProjectPages): Promise<IGetProjectResponse> {
  const offset = projectPages.offset ? "?offset=" + projectPages.offset : "?";
  const limit = projectPages.limit ? "&limit=" + projectPages.limit : "";
  const room_type_id = projectPages.room_type_id ? "&room_type=" + projectPages.room_type_id : "";
  const collection_id = projectPages.collection_id ? "&collection_id=" + projectPages.collection_id : "";
  const search_query = projectPages.search_query ? "&search_query=" + projectPages.search_query : "";
  const manufacturer_id = projectPages.manufacturer_id ? `&manufacturer_id=${projectPages.manufacturer_id}` : "";

  return axios
    .get(
      baseUrl5050 +
        `public/${projectPages.organization_id}/project${[offset, limit, room_type_id, manufacturer_id, collection_id, search_query].join("")}`,
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getPublicProjects:", err.response);
      return err.response;
    });
}

// Метод для получения всех проектов по ID сделки
export async function getProjectsByDeal({ id }: IDealRequestById): Promise<IAllProjectsByDeal> {
  return axios
    .get(baseUrl5050 + `project/by-deal/${id}`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при записи объекта в методе ==> getProjectsByDeal:", err.response);
      return err.response;
    });
}

// Метод для получения состава проекта
export async function getProjectItems(project_id: string): Promise<IGetProjectItems> {
  return axios
    .get(baseUrl5050 + `project/${project_id}/project_item/`, { headers: { Authorization: "Bearer " + Cookies.get("access_token") } })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getProjectItems:", err.response);
      return err.response;
    });
}

// Метод для получения состава проекта
export async function getPublicProjectItems(organization_id: string, project_id: string): Promise<IGetProjectItems> {
  return axios
    .get(baseUrl5050 + `public/${organization_id}/project/${project_id}/project_item/`)
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getPublicProjectItems:", err.response);
      return err.response;
    });
}

// Метод для изменения объекта по ID
export async function editProject(projectRequest: IProjectRequest): Promise<IProject> {
  if (!projectRequest.id) {
    throw new Error("Не передан ID объекта для изменений");
  }

  const id = projectRequest.id;
  const deal_id = projectRequest.deal_id;

  // удалим из объекта поля id, deal_id. Для корректонго запроса
  delete projectRequest.id;
  delete projectRequest.deal_id;

  return axios
    .put(baseUrl5050 + `deal/${deal_id}/project/${id}`, projectRequest, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при изменении объекта в методе ==> editProject:", err.response);
      return err.response;
    });
}

export async function updateProjectStatus(project: IProjectStatus): Promise<IProjectStatusResponse> {
  try {
    const request = await axios.put(
      baseUrl5050 + `gallery-project/${project.id}/status`,
      { status: project.status },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    );
    return {
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка в методе ==> updateProjectStatus:");
    throw new Error(err.request.status);
  }
}

export async function updatePublicProjectStatus(project: IProjectStatus): Promise<IProjectStatusResponse> {
  try {
    const request = await axios.put(
      baseUrl5050 + `project/${project.id}/status`,
      { status: project.status },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    );
    return {
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка в методе ==> updateProjectStatus:");
    throw new Error(err.request.status);
  }
}

export async function updateProjectTitle({ value, id }: { id: string; value: string }): Promise<{ data: IProject; status: number }> {
  ///
  try {
    const request = await axios.put(
      baseUrl5050 + `gallery-project/${id}/name`,
      { name: value },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    );
    return {
      status: request.status,
      data: request.data,
    };
  } catch (err: any) {
    console.error("Ошибка в методе ==> updateProjectTitle:");
    throw new Error(err.request.status);
  }
}

export async function getProjectNotificationText({
  deal_id,
  project_id,
}: {
  deal_id: string;
  project_id: string[] | null;
}): Promise<IGetNotificationText> {
  return axios
    .get(baseUrl5050 + `project/notification/text?deal_id=${deal_id}${project_id ? "&project_id=" + project_id : ""}`, {
      headers: { Authorization: "Bearer " + Cookies.get("access_token") },
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> getProjectNotificationText:", err.response);
      return err.response;
    });
}

export async function sendPanosToClients({ deal_id, text }: ISendPanosToClients): Promise<ISendPanosToClientResponse> {
  return axios
    .post(
      baseUrl5050 + `project/notification/text`,
      { deal_id, text },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при записи объекта в методе ==> sendPanosToClients:", err.response);
      return err.response;
    });
}

export async function changeProjectSaleStatus({ id, sale_status }: IChangeProjectSaleStatus): Promise<ISendPanosToClientResponse> {
  return axios
    .put(
      baseUrl5050 + `project/${id}/sale-status`,
      { sale_status },
      { headers: { Authorization: "Bearer " + Cookies.get("access_token") } },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при записи объекта в методе ==> changeProjectSaleStatus:", err.response);
      return err.response;
    });
}
