import { Tabs, message } from "antd";
import styles from "./GalleryCardDetailed.module.scss";
import {
  IPhotoJson,
  IProject,
  IProjectJson,
  getProjectById,
  getProjectJsonById,
  getProjectPhotosJsonById,
  getPublicProjectById,
  updateProjectStatus,
  updateProjectTitle,
  updatePublicProjectStatus,
} from "../../../services/Projects";
import React, { useEffect, useState } from "react";
import ProjectPanorama from "./ProjectPanorama";
import Loader from "../../../components/ui/Loader/Loader";
import { useMutation, useQuery } from "react-query";
import NotFound from "../../../components/NotFound/NotFound";
import { ReactComponent as QRIcon } from "../../../assets/icons/qr.svg";
import { ReactComponent as FullScreenIcon } from "../../../assets/icons/fullsize.svg";
import { ReactComponent as CloseFullSize } from "../../../assets/icons/closeFullSize.svg";
import { ReactComponent as CodeIcon } from "../../../assets/icons/code_icon.svg";
import "./GalleryCardDetailed_override.scss";
import ButtonCustom from "../../../components/ui/ButtonCustom/ButtonCustom";
import GalleryQRCode from "./GalleryQRCode";
import { useLocation } from "react-router-dom";
import { useUser } from "../../../context/useUser";
import content, { onlyAdmin, onlyLPR, Role } from "../../../settings/content";
import { ProjectStatusType } from "../../../helpers/projectStatus";
import ModerationStatus from "../../../components/ui/ModerationStatus/ModerationStatus";
import { NoticeType } from "antd/es/message/interface";
import ValidateComponentByRole from "../../../components/ValidateComponentByRole/ValidateComponentByRole";
import "./GalleryCardDetailed_override.scss";
import { yandexCloudUrl } from "../../../env";
import GalleryCode from "./GalleryCode";
import dayjs from "dayjs";
import { useSources } from "../../../context/useSources";
import InputWithViewMode from "../../../components/ui/InputCustom/InputWithViewMode";
import { Image } from "antd";
import ImageBroken from "../../../assets/placeholders/ImageBroken.svg";

interface IGalleryCardDetailed {
  selectedProjectId: string;
  isPublicGallery?: boolean;
  isCustomHeight?: number;
  isErrorHandler: (isError: boolean) => void;
  handleCardInfo?: (title: string) => void;
  isOpenedFromGallery?: boolean;
  isOpenedFromProjectView?: boolean;
  isOpenedFromIframe?: boolean;
  refetchProjects?: () => void;
  isMyProject?: boolean;
}

const GalleryCardDetailed: React.FC<IGalleryCardDetailed> = ({
  selectedProjectId,
  isPublicGallery,
  isCustomHeight,
  isErrorHandler,
  handleCardInfo,
  isOpenedFromGallery = false,
  isOpenedFromProjectView = false,
  isOpenedFromIframe = false,
  refetchProjects,
  isMyProject,
}) => {
  const location = useLocation();
  const { user, users } = useUser();
  const { room_types } = useSources();

  const { data: projectData, refetch } = useQuery({
    queryFn: () => (isPublicGallery ? getPublicProjectById(selectedProjectId) : getProjectById(selectedProjectId, !isMyProject)),
    queryKey: ["project", selectedProjectId, isMyProject],
  });

  const { mutateAsync: onProjectNameEdit } = useMutation({
    mutationFn: updateProjectTitle,
    onSuccess: () => {
      if (refetchProjects) {
        refetchProjects();
      }
    },
  });

  const {
    data: projectJsonData,
    isLoading,
    error,
  } = useQuery({
    queryFn: () => getProjectJsonById(selectedProjectId),
    queryKey: ["projectJson", selectedProjectId],
    onError: () => isErrorHandler(true),
  });

  const mutationFn = isPublicGallery ? updatePublicProjectStatus : updateProjectStatus;

  const { data: projectPhotosJsonData } = useQuery({
    queryFn: () => getProjectPhotosJsonById(selectedProjectId),
    queryKey: ["projectPhotoJson", selectedProjectId],
    onSuccess: (data) => {
      setPhoto(data.data.items);
    },
  });

  const { mutateAsync: onUpdateProjectStatus } = useMutation({
    mutationFn,
  });

  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState<IProject>();
  const [pano, setPano] = useState<IProjectJson[]>([]);
  const [photo, setPhoto] = useState<IPhotoJson[]>([]);
  const [qrModal, setQrModal] = useState(false);
  const [codeModal, setCodeModal] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<string>("0");
  const [isFullSize, setIsFullSize] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setData(projectData?.data ? projectData?.data : undefined);
  }, [projectData, selectedProjectId]);

  useEffect(() => {
    if (!projectJsonData) {
      return;
    }
    setPano(projectJsonData.data.items);
  }, [projectJsonData, selectedProjectId, projectData, error, isLoading]);

  const handleCloseQr = () => setQrModal(false);
  const handleCloseCode = () => setCodeModal(false);

  // Во время загрузки или ошибки панорамы будем блокировать кнопку "Создать на основании проекта"
  useEffect(() => {
    if (isLoading || error) {
      isErrorHandler(true);
      return;
    }
    isErrorHandler(false);
  }, [isLoading, error]);

  useEffect(() => {
    if (!projectData || !handleCardInfo) {
      return;
    }
    handleCardInfo(projectData?.data.name);
  }, [projectData]);

  const editStatusHandler = async (status: ProjectStatusType) => {
    if (!data?.id) {
      return;
    }
    onUpdateProjectStatus({ id: data.id, status })
      .then((response) => {
        if (response.status === 200) {
          alert("success", content.alert_messages.projectStatus.success);
          refetch();
        }
      })
      .catch((e) => alert("success", content.alert_messages.projectStatus.error));
  };

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  if (!data) {
    return <Loader />;
  }

  const panoTabItem = (
    <Tabs
      defaultActiveKey="0"
      tabPosition={screenWidth > 600 ? "left" : "top"}
      style={{ height: isCustomHeight || 380 }}
      onChange={(tab) => setCurrentTabIndex(tab)}
      destroyInactiveTabPane={true}
      items={pano?.map((item, i) => {
        const id = String(i);
        return {
          label: (
            <div
              className={styles.gallery_card_detailed__label}
              style={{ backgroundImage: `url(${`${yandexCloudUrl}${data.id}/pano/${item?.preview}`})` }}
            >
              <img alt="preview" style={{ width: "100%", height: "auto" }} src={`${yandexCloudUrl}${data.id}/pano/${item?.preview}`} />
            </div>
          ),
          key: id,
          children: (
            <div>
              <div>
                <div
                  style={{
                    height: isFullSize ? "100vh" : isCustomHeight ? `${isCustomHeight}px` : "380px",
                  }}
                  className={isFullSize ? "panorama_custom panorama_custom_fullsize" : "panorama_custom"}
                >
                  {/* Кнопка для iphone, не удалять */}
                  {/iPhone/.test(navigator.userAgent) && (
                    <div className={styles.gallery_card_detailed__ios_btn} onClick={() => setIsFullSize(!isFullSize)}>
                      {isFullSize ? <CloseFullSize /> : <FullScreenIcon />}
                    </div>
                  )}
                  <ProjectPanorama
                    selectedProjectId={selectedProjectId}
                    id={id}
                    panoramaId={data?.id}
                    sceneId={`${id} scene`}
                    height="100%  "
                    metaData={item}
                    currentTabIndex={currentTabIndex}
                    isFullSize={isFullSize}
                  />
                </div>
                {(!isOpenedFromProjectView || isOpenedFromIframe) && (
                  <div className={styles.gallery_card_detailed__qr}>
                    <ButtonCustom isTransparent className={styles.gallery_card_detailed__qr_btn} onClick={() => setQrModal(true)}>
                      <QRIcon />
                    </ButtonCustom>
                  </div>
                )}
                <ValidateComponentByRole requiredRoles={[...onlyLPR, ...onlyAdmin]}>
                  {isOpenedFromGallery && !isPublicGallery && !isOpenedFromProjectView && (
                    <div className={styles.gallery_card_detailed__code}>
                      <ButtonCustom isTransparent className={styles.gallery_card_detailed__code_btn} onClick={() => setCodeModal(true)}>
                        <CodeIcon width={40} height={40} className={styles.gallery_card_detailed__svg} />
                      </ButtonCustom>
                    </div>
                  )}
                </ValidateComponentByRole>
                <GalleryQRCode
                  id={id}
                  item={item}
                  qrModal={qrModal}
                  handleCloseQr={handleCloseQr}
                  projectId={selectedProjectId}
                  organizationId={isPublicGallery ? location.pathname.replace(/^\/public-gallery\//, "") : user.organization_id}
                  isOpenedFromGallery={isOpenedFromGallery}
                  isOpenedFromIframe={isOpenedFromIframe}
                />
                <GalleryCode
                  id={id}
                  item={item}
                  qrModal={codeModal}
                  handleCloseModal={handleCloseCode}
                  projectId={selectedProjectId}
                  organizationId={isPublicGallery ? location.pathname.replace(/^\/public-gallery\//, "") : user.organization_id}
                  isOpenedFromGallery={isOpenedFromGallery}
                />
              </div>
            </div>
          ),
        };
      })}
    />
  );

  const photoTabItem = (
    <Tabs
      defaultActiveKey="0"
      tabPosition={screenWidth > 600 ? "left" : "top"}
      style={{ height: isCustomHeight ? isCustomHeight : 400 }}
      onChange={(tab) => setCurrentTabIndex(tab)}
      destroyInactiveTabPane={true}
      items={photo?.map((item, i) => {
        const id = String(i);
        return {
          label: (
            <div className={styles.gallery_card_detailed__label}>
              <img
                alt="preview"
                className={styles.previewImageStyle}
                src={`${yandexCloudUrl}${data.id}/${item?.file}`}
                onError={(e) => {
                  const target = e.currentTarget;
                  target.onerror = null;
                  target.src = ImageBroken;
                }}
              />
            </div>
          ),
          key: id + "photo",
          children: (
            <div
              style={{
                height: isFullSize ? "100vh" : isCustomHeight ? `${isCustomHeight}px` : "380px",
              }}
            >
              <Image
                width={"100%"}
                height={"100%"}
                className={styles.selectedImageStyle}
                alt="Фото"
                src={`${yandexCloudUrl}${data.id}/${item?.file}`}
                fallback={ImageBroken}
              />
            </div>
          ),
        };
      })}
    />
  );

  return (
    <div className={styles.gallery_card_detailed}>
      {contextHolder}
      {!isPublicGallery && user.role !== Role.DEPARTMENT_EMPLOYEE && (
        <ModerationStatus status={projectData?.data.status as ProjectStatusType} />
      )}
      {!isPublicGallery && [...onlyLPR, ...onlyAdmin].includes(user?.role as Role) ? (
        <div className={styles.gallery__card_title_block}>
          <InputWithViewMode
            width="300px"
            isBoldText
            inputName="projectName"
            inputValue={data.name}
            inputPlaceholder="Название проекта"
            id={data.id}
            onSubmit={onProjectNameEdit}
            maxLength={30}
          />
          {data?.number && <span className={styles.gallery__card_number}>№{data?.number}</span>}
        </div>
      ) : (
        <div className={styles.gallery__card_title_block}>
          <p className={styles.gallery__card_title}>{data.name ? data.name : "Без названия"}</p>
          {data?.number && <span className={styles.gallery__card_number}>№{data?.number}</span>}
        </div>
      )}
      {!isPublicGallery && data && (
        <>
          <span className={styles.gallery_card_detailed_info}>
            Тип помещения: {room_types.find((item) => item.value === data.room_type)?.name}
          </span>
          <span className={styles.gallery_card_detailed_info}>Дата создания: {dayjs(data.created_at).format("DD.MM.YYYY")}</span>
          <span className={styles.gallery_card_detailed_info}>
            Автор: {users.find((item) => item.id === data.created_by)?.name ?? "Без имени"}
          </span>
        </>
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <span className={styles.gallery_card_detailed__error}>{content.gallery.error_on_load}</span>
      ) : pano.length && data ? (
        <div className={styles.gallery_card_detailed__content}>
          <Tabs
            defaultActiveKey="0"
            tabBarStyle={{
              color: "lightgray",
              fontWeight: "400",
              fontFamily: "Roboto",
              fontSize: "14px",
              letterSpacing: "0.3",
            }}
            tabPosition="top"
            onChange={(tab) => setCurrentTabIndex(tab)}
            destroyInactiveTabPane={true}
            items={(() => {
              const result: { label: string; key: string; children: React.ReactNode }[] = [
                { label: "Панорамы", key: "0", children: panoTabItem },
              ];
              if (photo.length > 0) {
                result.push({ label: "Фотографии", key: "1", children: photoTabItem });
              }
              return result;
            })()}
          />
        </div>
      ) : (
        <NotFound title="Не удалось получить данные о проекте" />
      )}
      {data && (
        <>
          <ValidateComponentByRole requiredRoles={[...onlyLPR, ...onlyAdmin]}>
            <div className={styles.gallery_card_detailed__buttons}>
              {data.status !== "created" && data.status !== "hidden" && (
                <ButtonCustom
                  className={styles.gallery_card_detailed__button_hidden}
                  onClick={() => editStatusHandler(ProjectStatusType.HIDDEN)}
                >
                  <span>{content.gallery.statusBtns.hidden}</span>
                </ButtonCustom>
              )}
              {data.status !== "published" && (
                <ButtonCustom
                  className={styles.gallery_card_detailed__button_show}
                  onClick={() => editStatusHandler(ProjectStatusType.PUBLISHED)}
                >
                  <span>{content.gallery.statusBtns.published}</span>
                </ButtonCustom>
              )}
            </div>
          </ValidateComponentByRole>
        </>
      )}
    </div>
  );
};

export default GalleryCardDetailed;
