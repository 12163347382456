import React, { Dispatch, ReactElement, useContext, useEffect, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { IStuffUser, getAllStuff, getCurrentUserInfo } from "../services/User";
import { checkAuthenticated } from "../helpers/checkAuth";

interface IUserProps {
  children: ReactElement;
}

export interface IUser {
  department_id: string;
  shop_id: string | null;
  email: string;
  id: string;
  login: string;
  name: string;
  phone: string;
  role: string;
  organization_id: string;
}

interface IUserContext {
  user: IUser;
  users: IStuffUser[];
}

const initialUser: IUser = {
  email: "",
  id: "",
  login: "",
  name: "",
  phone: "",
  role: "",
  organization_id: "",
  shop_id: "",
  department_id: "",
};
const initialContext: IUserContext = {
  user: { ...initialUser },
  users: [],
};

const UserContext = React.createContext<IUserContext>(initialContext);

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider: React.FC<IUserProps> = ({ children }) => {
  const authenticated = checkAuthenticated();

  const [user, setUser] = useState<IUser>(initialUser);
  const [usersData, setUsersData] = useState<IStuffUser[]>([]);

  const { data: userInfo } = useQuery({
    queryFn: () => getCurrentUserInfo(),
    queryKey: ["user"],
    enabled: authenticated,
  });
  const { data: users } = useQuery({
    queryFn: () => getAllStuff(),
    queryKey: ["users"],
    enabled: authenticated,
  });

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    setUser(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (!users || !users?.data) {
      return;
    }
    if (users?.status === 403) {
      return;
    }
    setUsersData(users.data);
  }, [users]);

  return <UserContext.Provider value={{ user, users: usersData }}>{children}</UserContext.Provider>;
};
