import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { IDealFilterValue } from "./DealFilters";
import { saveAs } from "file-saver";
import { buildPresalesUrl, buildAuthHeader } from "../shared";

interface IDealRequest {
  id?: string;
  title?: string;
  client_id: string;
  room_type?: string;
  surface_area?: number | string;
  renovation_stage?: string;
  estate_name?: string;
  comment?: string;
}

interface IDealCreateRequest {
  id?: string;
  title?: string;
  client_id: string;
  room_type?: string;
  surface_area?: number | string;
  renovation_stage?: string;
  estate_name?: string;
  comment?: string;
}

export interface IGetAllDeals {
  status: number;
  data: {
    groups: IDealResponse[];
  };
}

export interface IGetAllDealsRemindersCount {
  data: { id: string; reminders_count: number }[];
  status: number;
}

export interface IDealResponse {
  status: string;
  deals: IDeals[];
}

export interface ISingleDealResponse {
  status: string;
  data: IDeal;
}

export interface IDeals {
  id: string;
  client_fullname: string;
  client_id: string;
  comment: string;
  created_by: string;
  created_at: string;
  department_id: string;
  estate_name: string;
  renovation_stage: string;
  room_type: string;
  status: string;
  surface_area: number;
  title: string;
  reminders_count?: number;
  shop_id: string;
}

export interface IDeal {
  client_fullname: string;
  id: string;
  client_id: string;
  comment: string;
  created_by: string;
  created_at: string;
  department_id: string;
  estate_name: string;
  renovation_stage: string;
  room_type: string;
  status: string;
  surface_area: number;
  title: string;
  reminders_count?: number;
  shop_id: string;
}

export interface IDealSearch {
  search?: string;
}

export interface IDealAddResponse {
  id: string;
  data: IDealResponse;
  status: number;
}

export interface IDealRequestById {
  id: string;
}

export interface IDealStatusResponse {
  id: string;
  value: string;
  disabled: boolean;
  name: string;
  editable?: boolean;
}

export interface IEditDealStatusRequest {
  dealId: string;
  status: string;
}

export interface IEditStatusesRequest {
  statuses: { id: string; disabled: boolean }[];
}

export interface IDealHistoryStatusResponse {
  created_at: string;
  created_by: string;
  status: string;
  timestamp: string;
}

export interface IDealCommentsHistoryResponse {
  id: string;
  deal_id: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  text: string;
}

export interface ICreateDraft {
  parent_deal_id: string;
  target_department_id: string;
  comment?: string;
}

export interface IGetAllDraftsDeals {
  data: IDraftsData[];
  status: number;
}

export interface IDraftsData {
  comment?: string;
  created_at: string;
  created_by: string;
  id: string;
  parent_deal_id: string;
  target_department_id: string;
}

export interface IGetDraftDealsCountResponse {
  data: {
    count: number;
  };
  status: number;
}

export enum SendingMethodsEnum {
  BOTH = "both",
  EMAIL = "email",
  PHONE = "phone",
  NONE = "none",
}

export const SendingMethodsList = [
  {
    value: SendingMethodsEnum.BOTH,
    label: "Все",
  },
  {
    value: SendingMethodsEnum.EMAIL,
    label: "Email",
  },
  {
    value: SendingMethodsEnum.PHONE,
    label: "Телефон",
  },
  // {
  //   value: SendingMethodsEnum.NONE,
  //   label: "Никому",
  // },
];

export interface IDealDashboardPayload {
  search_query: string | null;
  shop_ids: string[] | null;
  department_ids: string[] | null;
  user_ids: string[] | null;
  period_start: string | null;
  period_end: string | null;
}

const formAuthHeader = () => {
  return {
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  };
};

export async function addDeal(dealRequest: IDealCreateRequest): Promise<IDealAddResponse> {
  return axios
    .post(buildPresalesUrl("deal"), dealRequest, { headers: formAuthHeader() })
    .then((response) => {
      return {
        id: response.data?.id,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при записи объекта в методе ==> addDeal:", err.response);
      return err.response;
    });
}

// Метод для получения объекта из БД по ID
export async function getDeal({ id }: IDealRequestById): Promise<ISingleDealResponse> {
  return axios
    .get(buildPresalesUrl(`deal/${id}`), { headers: buildAuthHeader() })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getDeal:", err.response);
      return err.response;
    });
}

// Метод для получения всех объектов из БД

export async function getAllDeals({ dealSearch, filters }: { dealSearch: IDealSearch; filters?: IDealFilterValue }): Promise<IGetAllDeals> {
  const payloadData: IDealDashboardPayload = {
    search_query: dealSearch?.search ? dealSearch.search : null,
    shop_ids: null,
    department_ids: null,
    user_ids: null,
    period_start: null,
    period_end: null,
  };
  if (filters) {
    payloadData.shop_ids = (filters.shop_ids ?? []).length > 0 ? filters.shop_ids : null;
    payloadData.department_ids = (filters.department_ids ?? []).length > 0 ? filters.department_ids : null;
    payloadData.user_ids = (filters.user_ids ?? []).length > 0 ? filters.user_ids : null;
    payloadData.period_start = filters?.period_start && filters.period_start !== "" ? filters.period_start : null;
    payloadData.period_end = filters?.period_end && filters.period_end !== "" ? filters.period_end : null;
  }
  try {
    const request = await axios.put(
      buildPresalesUrl(`deal/dashboard/`),
      {
        ...payloadData,
      },
      {
        headers: buildAuthHeader(),
      },
    );
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getAllDeals:");
    throw new Error(err.request.status);
  }
}

export async function getAllDealsRemindersCount(): Promise<IGetAllDealsRemindersCount> {
  try {
    const request = await axios.get(buildPresalesUrl(`deal/reminders-count`), {
      headers: formAuthHeader(),
    });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getAllDealsRemindersCount:");
    throw new Error(err.request.status);
  }
}

// Метод для изменения объекта по ID
export async function editDeal(dealRequest: IDealRequest): Promise<IGetAllDeals> {
  if (!dealRequest.id) {
    throw new Error("Не передан ID объекта для изменений");
  }

  const id = dealRequest.id;

  // удалим из объекта поле id. Для корректонго запроса
  delete dealRequest.id;

  return axios
    .put(buildPresalesUrl(`deal/${id}`), dealRequest, { headers: buildAuthHeader() })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при изменении объекта в методе ==> editDeal:", err.response);
      return err.response;
    });
}

// Метод для изменения статуса сделки по ID
export async function editDealStatus({ dealId, status }: IEditDealStatusRequest): Promise<any> {
  /// any - потому что ответ пустой
  return axios
    .put(buildPresalesUrl(`deal/${dealId}/status`), { status }, { headers: buildAuthHeader() })
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        dealId,
      };
    })
    .catch((err) => {
      console.log("Ошибка при изменении статуса сделки в методе ==> editDealStatus:", err.response);
      return err.response;
    });
}

// Метод для получения всех статусов для сделок из БД
export async function getDealStatuses(): Promise<IDealStatusResponse[]> {
  try {
    const request = await axios.get(buildPresalesUrl("deal/status"), { headers: buildAuthHeader() });
    return request.data;
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов статусов в методе ==> getDealStatuses:");
    throw new Error(err.request.status);
  }
}

/// Метод для включения/выключения статуса для доски сделок
export async function editStatus({ statuses }: IEditStatusesRequest): Promise<any> {
  /// any - потому что ответ пустой
  return axios
    .put(buildPresalesUrl("deal/status"), [...statuses], { headers: buildAuthHeader() })
    .then((response) => {
      return {
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при изменении статуса в методе ==> editStatus:", err.response);
      return err.response;
    });
}

// Метод для получения истории статусов сделок из БД
export async function getHistoryDealStatuses(deal_id: string): Promise<IDealHistoryStatusResponse[]> {
  return axios
    .get(buildPresalesUrl(`deal/${deal_id}/status/history`), { headers: buildAuthHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов статусов в методе ==> getHistoryDealStatuses:", err.response);
      return err.response;
    });
}

// Метод для получения истории комментариев сделок из БД
export async function getCommentsHistoryByDeal(deal_id: string): Promise<IDealCommentsHistoryResponse[]> {
  return axios
    .get(buildPresalesUrl(`deal/${deal_id}/comment`), { headers: buildAuthHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов статусов в методе ==> getCommentsHistoryByDeal:", err.response);
      return err.response;
    });
}

// Метод для отправки комментария в сделке
export async function sendComment({ deal_id, text }: { deal_id: string; text: string }): Promise<any> {
  return axios
    .post(buildPresalesUrl(`deal/${deal_id}/comment`), { text }, { headers: buildAuthHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> sendComment:", err.response);
      return err.response;
    });
}

// Метод для редактирования комментария в сделке
export async function editComment({ comment_id, text }: { comment_id: string; text: string }): Promise<any> {
  return axios
    .put(buildPresalesUrl(`deal/comment/${comment_id}`), { text }, { headers: buildAuthHeader() })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> editComment:", err.response);
      return err.response;
    });
}

// Метод для редактирования комментария в сделке
export async function deleteComment({ comment_id }: { comment_id: string }): Promise<any> {
  return axios
    .delete(buildPresalesUrl(`deal/comment/${comment_id}`), { headers: buildAuthHeader() })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("Ошибка в методе ==> deleteComment:", err.response);
      return err.response;
    });
}

// Метод для получения всех лидов / drafts из БД
export async function getAllDraftsDeals(): Promise<IGetAllDraftsDeals> {
  try {
    const request = await axios.get(buildPresalesUrl("deal-draft"), { headers: buildAuthHeader() });
    return {
      data: request.data,
      status: request.status,
    };
  } catch (err: any) {
    console.error("Ошибка при получении объекта/ов в методе ==> getAllDratsDeals:");
    throw new Error(err.request.status);
  }
}

// Метод для создания лида
export async function createDraftDeal(draftRequest: ICreateDraft): Promise<IGetAllDeals> {
  return axios
    .post(buildPresalesUrl("deal-draft"), draftRequest, { headers: buildAuthHeader() })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getAllDratsDeals:", err.response);
      return err.response;
    });
}

// Метод для переноса лида / draft в сделки
export async function acceptDraftDealRequest(draft_deal_id: string): Promise<IGetAllDeals> {
  return axios
    .post(buildPresalesUrl(`deal/from-draft/${draft_deal_id}`), draft_deal_id, {
      headers: buildAuthHeader(),
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getAllDratsDeals:", err.response);
      return err.response;
    });
}

// Метод для количества лидов

export async function getDraftDealsCount(): Promise<IGetDraftDealsCountResponse> {
  return axios
    .get(buildPresalesUrl("deal-draft/count"), { headers: buildAuthHeader() })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getDraftDealsCount:", err.response);
      return err.response;
    });
}

export async function getNotificationSettingsById(
  deal_id: string,
): Promise<{ data: { sending_method: SendingMethodsEnum }; status: number }> {
  return axios
    .get(buildPresalesUrl(`notification-settings/deal/${deal_id}`), {
      headers: buildAuthHeader(),
    })
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> getNotificationSettingsById:", err.response);
      return err.response;
    });
}

export async function postNotificationSettingsById({
  deal_id,
  sending_method,
}: {
  deal_id: string;
  sending_method: SendingMethodsEnum;
}): Promise<{ data: { sending_method: SendingMethodsEnum }; status: number }> {
  return axios
    .post(
      buildPresalesUrl(`notification-settings/deal/${deal_id}`),
      { sending_method },
      {
        headers: buildAuthHeader(),
      },
    )
    .then((response) => {
      return {
        data: response.data,
        status: response.status,
      };
    })
    .catch((err) => {
      console.log("Ошибка при получении объекта/ов в методе ==> postNotificationSettingsById:", err.response);
      return err.response;
    });
}

export async function downloadDealsExcelReport() {
  try {
    const response: AxiosResponse<Blob> = await axios.get(buildPresalesUrl("deal/export"), {
      responseType: "blob",
      headers: buildAuthHeader(),
    });

    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    saveAs(blob, "deals_report.xlsx");
  } catch (error) {
    console.log("Не удалось скачать отчёт по сделкам");
  }
}
