import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import { UserProvider } from "../context/useUser";
import { CitiesProvider } from "../context/useCities";
import { NotificationsProvider } from "../context/useNotifications";
import { NotificationProvider } from "../context/useAntdNotification";
import { ShopsProvider } from "../context/useShops";
import { WorkstationsProvider } from "../context/useWorkstations";
import { ClientsProvider } from "../context/useClients";
import { LeadsProvider } from "../context/useLeads";
import { SourcesProvider } from "../context/useSources";
import { AppRouter } from "./router";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // cacheTime: 5000,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <NotificationProvider>
        <NotificationsProvider>
          <ShopsProvider>
            <CitiesProvider>
              <UserProvider>
                <WorkstationsProvider>
                  <ClientsProvider>
                    {/*<LeadsProvider>*/}
                    <SourcesProvider>
                      <AppRouter />
                    </SourcesProvider>
                    {/*</LeadsProvider>*/}
                  </ClientsProvider>
                </WorkstationsProvider>
              </UserProvider>
            </CitiesProvider>
          </ShopsProvider>
        </NotificationsProvider>
      </NotificationProvider>
    </QueryClientProvider>
  );
}

export default App;
