import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styles from "./AnalyticsClients.module.scss";
import "./AnalyticsClients_override.scss";
import { message } from "antd";
import { useDebounce } from "@uidotdev/usehooks";
import { IClient, getAllClients } from "../../../services/Clients";
import SearchBar from "../../SearchBar/SearchBar";
import ClientCard from "../../../pages/DealsPage/components/DealCardCreate/ClientCard/ClientCard";
import Loader from "../../ui/Loader/Loader";
import ComponentError from "../../ui/ComponentError/ComponentError";
import AnalyticsClientsTable from "./AnalyticsClientsTable";
import { NoticeType } from "antd/es/message/interface";
import { DownloadClientsXlsx } from "../../../features/DownloadClientsXlsx";
import ValidateComponentByRole from "../../ValidateComponentByRole/ValidateComponentByRole";
import { onlyAdmin, onlyLPR } from "../../../settings/content";

type Props = {};

export interface ISelect {
  label: string;
  value: string;
}

const Clients: React.FC<Props> = ({}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [search, setSearch] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<IClient>();

  const alert = (type: NoticeType, content: string) => {
    messageApi.open({
      type: type,
      content: content,
    });
  };

  const {
    data: clientsData,
    refetch,
    isLoading,
    error,
  } = useQuery({
    queryFn: () => getAllClients({ search: search }),
    queryKey: [`clientss`],
  });

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    refetch();
  }, [debouncedSearch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const refetchClient = (updatedClient: IClient) => {
    refetch();
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ComponentError />;
  }

  return (
    <div className={styles.clients}>
      {contextHolder}
      <div className={styles.clients__table_wrapper}>
        <SearchBar className={styles.clients__header_search} placeholder={"Поиск по клиентам"} onChange={handleChange} value={search} />
        <ValidateComponentByRole requiredRoles={[...onlyLPR, ...onlyAdmin]}>
          <DownloadClientsXlsx />
        </ValidateComponentByRole>
      </div>
      <AnalyticsClientsTable search={debouncedSearch} setSelectedClient={setSelectedClient} />
      <ClientCard
        isOpen={!!selectedClient}
        client={selectedClient}
        onCancel={() => setSelectedClient(undefined)}
        refetchClient={refetchClient}
      />
    </div>
  );
};
export default Clients;
